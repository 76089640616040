/* webpackChunkName: "Benefit" */

import { container, headlineStyle, left, right } from "./index.module.scss"

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import TextRenderer from '../text/renderer'
import { graphql } from 'gatsby'

const Benefit = ({ id, headline, body, visual }) => {
  if (!headline || !body) return null

  return (
    <section id={id}>
      <div className={container}>
        <div className={left}>
          {( visual &&
            <GatsbyImage image={visual?.localFile?.childImageSharp?.gatsbyImageData} alt={headline}/>
          )}
        </div>
        <div className={right}>
          <h2 className={headlineStyle}>{headline}</h2>
          <div>
            <TextRenderer text={body?.body}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefit

export const query  = graphql`
  fragment Benefit on ContentfulBenefit {
    __typename
    contentful_id
    headline
    body {
      body
    }
    visual {
      id
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.56
            width: 555
            formats: [AUTO, WEBP, AVIF]
            quality: 75
            transformOptions: {
              cropFocus: CENTER
            }
          )
        }
      }
    }
  }
`
